




























import { Component, Vue, Prop } from 'vue-property-decorator';

import { WorkPlatformService } from '@WorkPlatform/services/index';

import { namespace } from 'vuex-class';

import { service } from '@cloudpivot-hermes/nail-free-login';

import { isPhone } from '@WorkPlatform/common/utils';
import { CommonServices } from '@WorkPlatform/common/service';

const workPlatformModule = namespace('workPlatform');

@Component({
  name: 'app-page',
  components: {
    AppImage: () => import('./app-image.vue'),
  },
})
export default class ComponentName extends Vue {
  @Prop({ default: [] }) list!: any[];

  @workPlatformModule.State('recentIds') recentIds: any;

  @workPlatformModule.Action('setRecentIds') setRecentIds: any;

  @workPlatformModule.Action('setOnlineApps') setOnlineApps: any;

  get IsDingTalk () {
    return sessionStorage.IS_DINGTALK === 'true';
  }

  get isFreeLoginPlatform () {
    return sessionStorage.IS_DINGTALK === 'true' || sessionStorage.IS_WECHAT === 'true';
  }

  get ISWECHAT () {
    return sessionStorage.IS_WECHAT === 'true';
  }

  clickHandler (item: any) {
    const params: any = {
      appCode: item.appCode,
      appVersion: item.version,
    };

    localStorage.setItem('appEntrance', 'platform');

    const winOpen: any = !this.isFreeLoginPlatform && window.open('', '_blank');

    WorkPlatformService.getAppUrl(params).then((res: any) => {
      if (res.success) {
        const homePage: any = item.homepages || {};
        let query: any = {
          groupCode: '',
          pageGroupCode: '',
          pageCode: '',
          ...homePage,
          appVersion: item.version,
          workflowCode: item.workflowCode,
        };
        query = CommonServices.setLoginParam(query);
        const str: string = Object.entries(query)
          .map((queryArr: any[]) => {
            return queryArr.join('=');
          })
          .join('&');

        let url = `${res.data}?${str}`;

        if (process.env.NODE_ENV !== 'production') {
          url = `http://localhost${res.data.substring(
            res.data.indexOf('/app_launcher/'),
          )}?${str}`;
        }

        this.setRecentApp(item.id);

        try {
          const token = localStorage.getItem('token');
          const wait: number = 100;
          if (this.ISWECHAT && isPhone()) {
            window.open(
              `${url}&token=${token}`,
            );
          } else if (this.isFreeLoginPlatform) {
            service.openLink(`${url}&token=${token}`);
          } else {
            if (!winOpen) {
              throw new Error('浏览器新开弹窗未开启');
            }

            setTimeout(() => {
              winOpen.location = url;
            }, wait);
          }
        } catch (e) {
          Vue.prototype.$errorcatcher.showMessagePopup({
            title: '错误提示！',
            message: [e, '!'],
            displayFooter: false,
            correctGuide: '请检查浏览器是否开启禁止新开弹窗！',
          });
        }
      }
    });
  }

  setRecentApp (id: string) {
    const ids: string | null = this.recentIds;
    let idsArr: any[] = [];
    if (Array.isArray(ids)) {
      idsArr = ids.filter((item: any) => item !== id);
    }
    idsArr.unshift(id);
    this.setRecentIds(idsArr);
  }

  get isMobile () {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(
      navigator.userAgent,
    );
  }
}
